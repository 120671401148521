import React, { Component } from "react";
import ReactGA from 'react-ga';
import { SocialIcon } from 'react-social-icons';
// import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import './App.css';
require('events').EventEmitter.defaultMaxListeners = 15;




class App extends Component {
  render(){
    const trackingId = 'UA-156099362-1'
    ReactGA.initialize(trackingId)
    ReactGA.pageview(window.location.pathname + window.location.search);
    // const audioUrl1 = require('./media/Flying_Low.wav')
    // const audioUrl2 = require('./media/Mostly_Sunny.wav')
    // const audioUrl3 = require('./media/Conversations.wav')
    // const audioList1 = [
    //   {
    //     name: 'Conversations',
    //     musicSrc: audioUrl3
    //   },
    //   {
    //     name: 'Mostly Sunny',
    //     musicSrc: audioUrl2
    //   },
    //   {
    //     name: 'Flying Low',
    //     musicSrc: audioUrl1
    //   }
    // ]

    // const options = {
    //   audioLists: audioList1,
    //   mode: 'mini',
    //   autoPlay: true,
    //   bounds: 'body'
    // }
  return (
    <>
    <div  className="container">
      <h1 className="title">Simply Terrapin</h1>
      <p className="social-icons">
        <SocialIcon url="https://open.spotify.com/artist/1TQrJ2WKMnCplpG4BtVvDP?si=ReyeIzzbQWCHIL_57gkvQw" bgColor="#C4C4C4" fgColor="#767676" style={{ height: 100, width: 100, margin:20 }}/> 
        <SocialIcon url="https://www.facebook.com/simplyterrapin/" bgColor="#C4C4C4" fgColor="#767676" style={{ height: 100, width: 100, margin:20 }}/>  
        <SocialIcon url="https://twitter.com/simplyterrapin" bgColor="#C4C4C4" fgColor="#767676" style={{ height: 100, width: 100, margin:20 }}/>  
        <SocialIcon url="https://www.youtube.com/channel/UC6Ywxp8dSOlTKYt9SLxBqtQ" bgColor="#C4C4C4" fgColor="#767676" style={{ height: 100, width: 100, margin:20 }}/>  
        <SocialIcon url="https://www.instagram.com/simplyterrapin/" bgColor="#C4C4C4" fgColor="#767676" style={{ height: 100, width: 100, margin:20 }}/> 
      </p>
    </div>
    <div className="audio">
      {/* <ReactJkMusicPlayer audioLists={audioList1} mode='full' showDownload={false} remove={false}/> */}
      {/* <ReactJkMusicPlayer {...options}/> */}
    </div>
    </>
  );
  }
}

export default App;